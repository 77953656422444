<script>
import useVuelidate from '@vuelidate/core';
import {required, maxValue} from '@vuelidate/validators';

/* COMPOSANTS */
import GaView from '@components/v2/layout/GaView';
import AkInputMoney from '@components/v2/input/AkInputMoney';
import AkInputPercentage from '@components/v2/input/AkInputPercentage';
import AkInputInteger from '@components/v2/input/AkInputInteger';
import AkButtonValidation from '@components/v2/general/AkButtonValidation.vue';
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination.vue';
import AkDialog from '@components/general/AkDialog.vue';
import AkInputText from '@components/v2/input/AkInputText.vue';
import AkButtonAction from '@components/v2/general/AkButtonAction.vue';
import GaListView from '@components/v2/layout/GaListView.vue';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import societeService from '@services/societeService';
import settingService from '@services/settingService';
import amountThresholdService from '@services/amountThresholdService';

export default {
  components: {
    GaListView,
    AkButtonAction,
    AkInputText,
    AkDialog,
    AkDatatablePagination,
    AkButtonValidation,
    AkInputPercentage,
    AkInputMoney,
    AkInputInteger,
    GaView,
  },
  mixins: [messageMixin, randomRef, utilsMixin],
  setup() {
    return {v$: useVuelidate()};
  },
  metaInfo() {
    return {
      title: 'settings.infos_mandat',
    };
  },
  data() {
    return {
      loading: true,
      currentTab: 'INFOS_MANDAT',
      submitted: false,
      societe: {},
      mandatNumSetting: {},
      amountThresholdList: [],
      AmountThresholdItem: {},
      crg: {val: false},
    };
  },
  validations() {
    return {
      AmountThresholdItem: {
        label: {required},
        amountGa: {required},
        amountGav: {required, maxValue: maxValue(this.AmountThresholdItem.amountGa)},
      },
    };
  },
  mounted() {
    const promises = [];

    promises.push(
      societeService.societeGuestadom().then((data) => {
        this.societe = data;
      }),
    );
    promises.push(
      settingService.setting('MANDATE_NUM').then((data) => {
        this.mandatNumSetting = data;
      }),
    );
    promises.push(
      amountThresholdService.amountThresholds().then((data) => {
        this.amountThresholdList = data;
      }),
    );
    promises.push(
      settingService.setting('SEND_CRG').then((data) => {
        this.crg = data;
        this.crg.val = this.crg.val === 'true';
      }),
    );

    Promise.all(promises).then(() => {
      this.loading = false;
    });
  },
  methods: {
    changeTab(tab) {
      this.currentTab = tab;
    },
    update() {
      this.submitted = true;
      this.loading = true;

      const promises = [];
      promises.push(
        societeService.update(this.societe).then((data) => {
          this.societe = data;
        }),
      );
      promises.push(
        settingService.update(this.mandatNumSetting).then((data) => {
          this.mandatNumSetting = data;
        }),
      );

      Promise.all(promises).then(() => {
        this.success(this.$t('updated'));
        this.loading = false;
        this.submitted = false;
        this.$refs.updateButton.init();
      });
    },

    createAmountThreshold() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.$refs.dialogCreateAmountThreshold.init();
        return;
      }

      this.loading = true;
      amountThresholdService
        .create(this.AmountThresholdItem)
        .then((data) => {
          this.amountThresholdList.push(data);
          this.item = {};
          this.success(this.$t('added'));
        })
        .finally(() => {
          this.loading = false;
          this.$refs.dialogCreateAmountThreshold.hide();
        });
    },
    updateAmountThreshold() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.$refs.dialogEditAmountThreshold.init();
        return;
      }

      this.loading = true;
      amountThresholdService
        .update(this.AmountThresholdItem)
        .then((data) => {
          this.replaceInListById(this.amountThresholdList, data);
          this.success(this.$t('updated'));
        })
        .finally(() => {
          this.loading = false;
          this.$refs.dialogEditAmountThreshold.hide();
        });
    },
    openCreateAmountThresholdDialog() {
      this.AmountThresholdItem = {};
      this.$refs.dialogCreateAmountThreshold.show();
    },
    openEditAmountThresholdDialog(item) {
      this.AmountThresholdItem = {...item};
      this.$refs.dialogEditAmountThreshold.show();
    },
    reorder(event) {
      const item = this.amountThresholdList[event.dragIndex];
      this.loading = true;
      amountThresholdService.reorderPosition(item, event.dropIndex - event.dragIndex).then(() => {
        this.reorderInListByIdAndDelta(this.amountThresholdList, item.id, event.dropIndex - event.dragIndex);
        this.loading = false;
      });
    },
  },
};
</script>

<template>
  <GaView :ref="ref" :title="$t('settings.infos_mandat')">
    <template #action>
      <AkButtonAction
        v-if="currentTab === 'AMOUNT_THRESHOLD'"
        :label="$t('add')"
        little
        @click="openCreateAmountThresholdDialog()" />
    </template>
    <template #content>
      <GaListView>
        <template #tabs>
          <div class="flex items-center">
            <span
              :class="[{active: currentTab === 'INFOS_MANDAT'}]"
              class="list-table-item"
              @click="changeTab('INFOS_MANDAT')"
              >{{ $t('settings.infos_mandat') }}</span
            >
            <span
              :class="[{active: currentTab === 'AMOUNT_THRESHOLD'}]"
              class="list-table-item"
              @click="changeTab('AMOUNT_THRESHOLD')"
              >{{ $t('mandat.amountThreshold') }}</span
            >
          </div>
        </template>

        <template #content>
          <template v-if="currentTab === 'INFOS_MANDAT'">
            <div class="grid md:grid-cols-2 grid-cols-1 md:gap-4 gap-2 mt-4">
              <AkInputPercentage
                v-model="societe.comGas"
                :disabled="loading"
                :label="$t('societe.comGas')"
                :submitted="submitted" />
              <AkInputPercentage
                v-model="societe.comGa"
                :disabled="loading"
                :label="$t('societe.comGa')"
                :submitted="submitted" />
              <AkInputMoney
                v-model="societe.amountMaxRenovation"
                :disabled="loading"
                :label="$t('societe.amountMaxRenovation')"
                :submitted="submitted" />
              <AkInputMoney
                v-model="societe.amountMaxEquipPurchase"
                :disabled="loading"
                :label="$t('societe.amountMaxEquipPurchase')"
                :submitted="submitted" />
              <AkInputMoney
                v-model="societe.feeCancel"
                :disabled="loading"
                :label="$t('societe.feeCancel')"
                :submitted="submitted" />
              <AkInputPercentage
                v-model="societe.feeCancelCom"
                :disabled="loading"
                :label="$t('societe.feeCancelCom')"
                :submitted="submitted" />
              <AkInputMoney
                v-model="societe.feeServiceVgaMinimum"
                :disabled="loading"
                :label="$t('societe.feeServiceVgaMinimum')"
                :submitted="submitted" />
              <AkInputPercentage
                v-model="societe.feeServiceVgaCom"
                :disabled="loading"
                :label="$t('societe.feeServiceVgaCom')"
                :submitted="submitted" />
              <AkInputPercentage
                v-model="societe.feeVrboCom"
                :disabled="loading"
                :label="$t('societe.feeVrboCom')"
                :submitted="submitted" />
              <div />
              <AkInputMoney
                v-model="societe.feeLitigation"
                :disabled="loading"
                :label="$t('societe.feeLitigation')"
                :submitted="submitted" />
              <AkInputInteger
                v-model="mandatNumSetting.val"
                :disabled="true"
                :label="$t('mandat.num')"
                :submitted="submitted" />
            </div>

            <div class="flex md:justify-end justify-center md:mt-4 mt-2">
              <AkButtonValidation ref="updateButton" :label="$t('update')" @action="this.update" />
            </div>
          </template>

          <template v-if="currentTab === 'AMOUNT_THRESHOLD'">
            <AkDatatablePagination
              :lazy="false"
              :loading="loading"
              :value="amountThresholdList"
              can-change-order
              class="mt-4"
              @row-reorder="reorder">
              <template #empty>
                {{ $t('stock.empty') }}
              </template>
              <Column :header="$t('amountThreshold.label')" field="label">
                <template #body="slotProps">
                  {{ slotProps.data.label }}
                </template>
              </Column>
              <Column :header="$t('amountThreshold.amountGa')" field="amountGa">
                <template #body="slotProps">
                  {{ formatCurrencyEUR(formatAmount(slotProps.data.amountGa)) }}
                </template>
              </Column>
              <Column :header="$t('amountThreshold.amountGav')" field="amountGav">
                <template #body="slotProps">
                  {{ formatCurrencyEUR(formatAmount(slotProps.data.amountGav)) }}
                </template>
              </Column>
              <Column style="width: 100px">
                <template #body="slotProps">
                  <div class="flex justify-end gap-2">
                    <span class="hover:cursor-pointer" @click="openEditAmountThresholdDialog(slotProps.data)">
                      <i class="ga-icon ga-edit text-2xl text-primary" />
                    </span>
                  </div>
                </template>
              </Column>
            </AkDatatablePagination>
          </template>
        </template>
      </GaListView>
    </template>

    <template #extra>
      <AkDialog
        ref="dialogCreateAmountThreshold"
        :title="$t('add')"
        :validate-label="$t('add')"
        width="450px"
        @validate="createAmountThreshold()">
        <div class="flex flex-col gap-2">
          <AkInputText
            v-model="AmountThresholdItem.label"
            :label="$t('amountThreshold.label')"
            :validator="v$.AmountThresholdItem.label" />
          <AkInputMoney
            v-model="AmountThresholdItem.amountGa"
            :label="$t('amountThreshold.amountGa')"
            :validator="v$.AmountThresholdItem.amountGa" />
          <AkInputMoney
            v-model="AmountThresholdItem.amountGav"
            :label="$t('amountThreshold.amountGav')"
            :validator="v$.AmountThresholdItem.amountGav" />
        </div>
      </AkDialog>
      <AkDialog
        ref="dialogEditAmountThreshold"
        :title="$t('amountThreshold.update')"
        :validate-label="$t('update')"
        width="450px"
        @validate="updateAmountThreshold()">
        <div class="flex flex-col gap-2">
          <AkInputText
            v-model="AmountThresholdItem.label"
            :label="$t('stock.label')"
            :validator="v$.AmountThresholdItem.label" />
        </div>
      </AkDialog>
    </template>
  </GaView>
</template>
